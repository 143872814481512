







































import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import CompHeader from "./CompHeaderLabel.vue";
import CompChartMacros from "./CompChartMacros.vue";
import patologiaModule from "@/store/modules/patologia-module";
import { TipoPatologia } from "@/utils/TipoPatologia";
import { patologia } from "@/shared/dtos/patologia";
@Component({
  components: { CompHeader, CompChartMacros }
})
export default class CompEstiloAlimentacion extends Vue {
  @PropSync("estilo", { type: String }) syncedestilo!: string;
  @Prop() index!: number;
  public estiloSeleccionado: any = {
    text: "",
    value: -1
  };
  public async created() {
    await patologiaModule.getpatologiasFromTipo(
      TipoPatologia.EstilosDeAlimentacion
    );
  }
  public get estilos() {
    let valores: any[] = [];
    for (
      let i = 0;
      i < patologiaModule.patologiasEstilosDeAlimentacion.length;
      i++
    ) {
      const element = patologiaModule.patologiasEstilosDeAlimentacion[i];
      valores.push({
        text: element.nombre_patologia,
        value: element.id
      });
      if (this.syncedestilo === element.nombre_patologia) {
        this.estiloSeleccionado = valores[valores.length - 1];
        this.$emit("validado", { index: this.index, valido: true });
      }
    }
    return valores;
  }
  @Watch("estiloSeleccionado")
  public ChangeEstilo() {
    for (
      let i = 0;
      i < patologiaModule.patologiasEstilosDeAlimentacion.length;
      i++
    ) {
      const element = patologiaModule.patologiasEstilosDeAlimentacion[i];
      if (element.id === this.estiloSeleccionado) {
        this.syncedestilo = element.nombre_patologia;
        this.$emit("validado", { index: this.index, valido: true });
      }
    }
  }
  public get ItemSelect(): patologia {
    for (
      let i = 0;
      i < patologiaModule.patologiasEstilosDeAlimentacion.length;
      i++
    ) {
      const element = patologiaModule.patologiasEstilosDeAlimentacion[i];
      if (this.syncedestilo === element.nombre_patologia) {
        this.$emit("validado", { index: this.index, valido: true });
        return element;
      }
    }
    return new patologia();
  }
}
