




import { Component, Vue, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
@Component({
  components: { VueApexCharts }
})
export default class CompChartMacros extends Vue {
  @Prop() pr!: number;
  @Prop() hc!: number;
  @Prop() gr!: number;
  public get series() {
    return [this.pr, this.hc, this.gr];
  }

  public get chartOptions() {
    return {
      labels: ["Proteínas.", "Carbohidratos ", "Grasas"],
      dataLabels: {
        enabled: false
      },
      legend: { show: false },
      chart: {
        type: "pie",
        offsetY: 0,
        dropShadow: {
          enabled: false,
          blur: 5,
          left: 1,
          top: 1,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      stroke: {
        width: 5
      },
      colors: ["#7961F9", "#FF9F43", "#EA5455"],
      fill: {
        type: "gradient",
        gradient: {
          gradientToColors: ["#9c8cfc", "#FFC085", "#f29292"]
        }
      }
    };
  }
}
